@font-face {
  font-family: 'Inter';
  src: url('https://cdn.bt.no/rnd/fonts/Inter-Regular.otf') format('opentype'),
    url('https://cdn.bt.no/rnd/fonts/Inter-Regular.woff') format('woff'),
    url('https://cdn.bt.no/rnd/fonts/Inter-Regular.woff2') format('woff2');
  font-weight: 400;
}

@font-face {
  font-family: 'Inter';
  src: url('https://cdn.bt.no/rnd/fonts/Inter-SemiBold.otf') format('opentype'),
    url('https://cdn.bt.no/rnd/fonts/Inter-SemiBold.woff') format('woff'),
    url('https://cdn.bt.no/rnd/fonts/Inter-SemiBold.woff2') format('woff2');
  font-weight: 600;
}

.bypassable-login-wall {
  display: none;
  box-sizing: border-box;
  z-index: 9999999;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(51, 51, 51, 0.8);
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  border-radius: 8px 8px 0px 0px;
  box-shadow: 0px -2px 33px 0px rgba(0, 0, 0, 0.5);
  color: #333;
  text-align: center;
  opacity: 0;

  &.bypassable-login-wall--open {
    display: block;
    animation-name: bypassableLoginWallFadeIn;
    animation-duration: 500ms;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
  }
  &.bypassable-login-wall--close {
    display: none;
  }

  &__wrapper {
    width: 100vw;
    border-radius: 16px;
    background-color: #fff;
    position: absolute;
    box-shadow: 0 0 60px 4px #333;
    top: initial;
    bottom: -15%;
    left: initial;
    right: initial;
    display: flex;
    flex-direction: column;
    opacity: 0;

    animation-name: bypassableLoginWallmoveBottom, bypassableLoginWallFadeIn;
    animation-duration: 600ms;
    animation-delay: 350ms, 500ms;
    animation-timing-function: cubic-bezier(0.32, 0.12, 0.16, 0.86);
    animation-fill-mode: forwards;

    &::before {
      content: 'Logg inn for å fortsette';
      position: absolute;
      left: 0;
      right: 0;
      top: -32px;
      color: #fff;
      font-size: 15px;
      font-weight: 600;
    }

    @media screen and (min-width: 500px) {
      width: 500px;
      bottom: initial;
      left: 50%;
      right: 50%;
      transform: translate(-50%, -50%);
      top: 65%;

      animation-name: bypassableLoginWallmoveTop, bypassableLoginWallFadeIn;
      animation-duration: 600ms;
      animation-delay: 350ms, 500ms;
      animation-timing-function: cubic-bezier(0.32, 0.12, 0.16, 0.86);
      animation-fill-mode: forwards;
    }
  }

  &__main {
    padding: 24px 40px;

    @media screen and (min-width: 500px) {
      padding: 40px 84px 24px;
    }

    @media screen and (max-width: 320px) {
      padding: 24px;
    }

    & .main__logo {
      width: 227px;
      margin-bottom: 16px;

      @media screen and (min-width: 500px) {
        width: 300px;
        margin-bottom: 26px;
      }
    }
  }

  &__form {
    label {
      font-size: 13px;
      line-height: 23px;
      text-align: left;
      float: left;
      width: 100%;
    }

    input {
      box-sizing: border-box;
      width: 100%;
      height: 50px;
      border: 1px solid #0068e4;
      border-radius: 7px;
      margin-bottom: 12px;
      padding: 12px 17px;
      line-height: 25px;

      &::placeholder {
        color: #696969;
        font-size: 16px;
        line-height: 24px;
      }
    }

    & .form__user {
      @media screen and (min-width: 500px) {
        margin-top: 24px;
      }

      & .user__display-name {
        font-size: 19px;
        font-weight: 600;
        margin-bottom: 6px;
      }

      & .user__account {
        font-size: 15px;
        margin-bottom: 16px;
      }
    }

    & .form__login-btn {
      cursor: pointer;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #00476d;
      border-radius: 25px;
      height: 50px;
      margin-bottom: 10px;
      padding: 0 30px;
      border: none;

      & .login-btn__avatar {
        margin-right: 18px;
        height: 25px;
      }

      & .login-btn__text {
        color: #fff;
        font-size: 18px;
        font-weight: 600;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    & .form__micro-text {
      font-size: 13px;
    }

    & .form__close-btn {
      cursor: pointer;
      color: #007cbd;
      background: none;
      font-size: 14px;
      border: none;
      border-bottom: 1px solid;
      padding: 0;
      margin-top: 20px;
      padding-bottom: 2px;
    }
  }

  &__footer {
    padding: 20px 40px;
    background-color: #f2f2f2;
    color: #666666;

    @media screen and (min-width: 500px) {
      padding: 24px 90px 18px;
      border-radius: 0 0 16px 16px;
    }

    @media screen and (max-width: 320px) {
      padding: 14px;
    }

    & .footer__logo {
      width: 234px;
      opacity: 0.6;
      transition: opacity 0.2s;
      margin-bottom: 16px;

      @media screen and (min-width: 500px) {
        width: 292px;
      }

      &:hover {
        opacity: 1;
      }
    }

    & .footer__text {
      font-size: 12px;
      margin-bottom: 10px;
      line-height: 20px;

      @media screen and (min-width: 500px) {
        font-size: 13px;
      }
    }

    & .footer__privacy-policy {
      font-size: 12px;
      border-bottom: 1px solid;
      padding-bottom: 2px;

      @media screen and (min-width: 500px) {
        font-size: 13px;
      }
    }
  }
}

@keyframes bypassableLoginWallFadeIn {
  from {
    oapcity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes bypassableLoginWallmoveTop {
  from {
    top: 65%;
  }
  to {
    top: 50%;
  }
}

@keyframes bypassableLoginWallmoveBottom {
  from {
    bottom: -15%;
  }
  to {
    bottom: 0;
  }
}
